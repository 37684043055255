<template>
  <v-container>
    <v-row>
      <v-col>
        <div class="tw-grid  sm:tw-grid-cols-1  md:tw-grid-cols-2 md:tw-gap-8  md:tw-my-3">
          <div class="tw-grid tw-grid-cols-1">
            <div>
              <div div class="tw-grid tw-grid-cols-3 tw-justify-items-start">
                <p class="c-gray-text tw-font-bold">List of Items</p>
                <p class="c-gray-text tw-font-bold">Availability</p>
                <p class="c-gray-text tw-font-bold">Comments</p>
              </div>
              <v-divider></v-divider>
              <v-form disabled v-for="(part, i) in getPartsByPage" :key="i" class="tw-grid tw-grid-cols-3">
                <p class="tw-self-center">{{ part | formatStatus }}</p>
                <p>
                  <v-radio-group v-model="getChecklistSelection[i]" row>
                    <v-radio label="Yes" value="true"></v-radio>
                    <v-radio label="No" value="false"></v-radio>
                  </v-radio-group>
                </p>
                <p>
                  <v-text-field label="Comment" v-model="getChecklistComments[i]"></v-text-field>
                </p>
              </v-form>
            </div>

          </div>
          <div class="tw-grid tw-grid-cols-1">
            <div>
              <div div class="tw-grid tw-grid-cols-3 tw-justify-items-start">
                <p class="c-gray-text tw-font-bold  md:tw-hidden">List of Items</p>
                <p class="c-gray-text tw-font-bold">Availability</p>
                <p class="c-gray-text tw-font-bold">New Comments</p>
              </div>
              <v-divider></v-divider>
              <v-form ref="checkoutForm" v-model="valid" lazy-validation>
                <div v-for="(part, i) in getPartsByPage" :key="i" class="tw-grid tw-grid-cols-3 ">
                  <p class="tw-self-center md:tw-hidden">{{ part | formatStatus }}</p>
                  <p class="tw-justify-self-center">
                    <v-radio-group v-model="newSelection[i]" row required :rules="[v => !!v || `${part}  is required`]">
                      <v-radio label="Yes" value="true"></v-radio>
                      <v-radio label="No" value="false"></v-radio>
                    </v-radio-group>
                  </p>
                  <p>
                    <v-text-field label="Comment" v-model="newComments[i]"></v-text-field>
                  </p>
                </div>
              </v-form>
            </div>

          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col class=" tw-grid tw-grid-cols-2 tw-justify-items-center">
        <v-btn v-if="this.step > 1" class="tw-w-1/2" @click="back" color="primary">Back</v-btn>
        <v-btn class="tw-w-1/2" @click="submit" v-if="this.step === 4" color="warning">Submit</v-btn>
        <v-btn class="tw-w-1/2" v-else @click="forward" color="primary">Next</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { formatStatus } from '../../../utils/helpers'
export default {
  props: { getPartsByPage: Array, getChecklistSelection: Array, step: Number, getChecklistComments: Array },
  data() {
    return {
      newSelection: [],
      newComments: [],
      valid: true
    }

  },
  methods: {
    forward() {
      const payload = [this.newSelection, this.newComments]
      if (this.$refs.checkoutForm.validate()) {
        this.$emit('forward-page', payload)
      } else {
        this.$toasted.error('Fill highlighted fields', {
          duration: 3000,
        })
      }
    },
    back() {
      this.$emit('back-page')
    },
    submit() {
      const payload = [this.newSelection, this.newComments]
      if (this.$refs.checkoutForm.validate()) {
        this.$emit('submit', payload)
      } else {
        this.$toasted.error('Fill highlighted fields', {
          duration: 3000,
        })
      }
    },
  },
  filters: {
    formatStatus
  },
}
</script>